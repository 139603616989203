import Icon from '@shared/components/Icon'
import {
  VerticalBarColors,
  VerticalRoundedBar,
} from '@shared/components/VerticalRoundedBar'
import { tw, twx } from '@shared/utils/tailwind'

export function SummarySectionList({
  barColor,
  title,
  children,
}: {
  title: string
  barColor?: VerticalBarColors
  children: React.ReactNode
}) {
  return (
    <>
      <div
        className={tw`mb-3 mt-5 text-[12px] font-semibold uppercase leading-[12px] text-gray-07`}
      >
        {title}
      </div>
      <div className={tw`flex flex-row justify-start`}>
        <VerticalRoundedBar color={barColor} />
        <ul className={tw`flex flex-col gap-[4px] text-sm`}>{children}</ul>
      </div>
    </>
  )
}

export function SummarySectionListItem({
  iconName,
  iconClass,
  children,
}: {
  iconName: string
  iconClass: string
  children: React.ReactNode
}) {
  return (
    <li className={tw`flex flex-row items-start gap-2 leading-relaxed`}>
      <Icon
        name={iconName}
        className={twx('fa-fw leading-relaxed', iconClass)}
      />
      {children}
    </li>
  )
}
