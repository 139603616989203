// eslint-disable-next-line check-file/filename-naming-convention
import { CSSProperties, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import Card from '@shared/components/Card'
import { twx } from '@shared/utils/tailwind'

interface LinkCardProps extends PropsWithChildren {
  id?: string
  style?: CSSProperties
  className?: string
  anchorClassName?: string
  href?: string
}

export function LinkCard({
  href,
  children,
  className,
  anchorClassName,
  ...rest
}: LinkCardProps) {
  const card = <Card className={twx(className)}>{children}</Card>

  if (href) {
    return (
      <Link
        to={href}
        className={twx('block no-underline', anchorClassName)}
        {...rest}
      >
        {card}
      </Link>
    )
  }

  // falsy herf will make card read-only, act like disable without disable styles
  return card
}
