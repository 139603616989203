import { ErrorResponse as _ErrorResponse } from '@augusthealth/models/com/august/protos/api/error_response'

export {
  type Error,
  type ErrorResponse,
  ErrorCode,
} from '@augusthealth/models/com/august/protos/api/error_response'

export class ApiError extends Error {
  status: number
  json?: _ErrorResponse
  requestId?: string
  internalCode?: string
  constructor({
    message,
    json,
    status,
    requestId,
  }: {
    message: string
    json?: _ErrorResponse
    status: number
    requestId?: string
  }) {
    super(message)
    this.name = 'ApiError'
    this.status = status
    this.json = json
    this.requestId = requestId
  }
}
