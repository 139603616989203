import Icon from '@shared/components/Icon'
import { LinkCard } from '@shared/components/LinkCard'
import Pill from '@shared/components/Pill'
import { Bed } from '@shared/types/bed'
import { HumanName } from '@shared/types/human_name'
import { OutOfFacilityDetail, Person } from '@shared/types/person'
import { Room } from '@shared/types/room'
import { PickPartial } from '@shared/types/utilities'
import { getFullName } from '@shared/utils/humanName'
import { formatRoomNumber, getProfileSvgPath } from '@shared/utils/person'
import { tw, twx } from '@shared/utils/tailwind'

type PersonToLink = Pick<Person, 'id' | 'gender'> & {
  name: HumanName
  profilePhotoUrl: string
  outOfFacilityDetail?: OutOfFacilityDetail | null
}

interface Props {
  person: PersonToLink
  roomInfo: {
    roomDetails?: Room
    bedDetails?: Bed
  }
  href: string
  cardClassName?: string
  anchorClassName?: string
  children: React.ReactNode
  headerEnd?: React.ReactNode
  disabled?: boolean
}

export const PersonCard = ({
  person,
  children,
  href,
  headerEnd,
  disabled = false,
  roomInfo,
  cardClassName,
  anchorClassName,
}: Props) => {
  const formattedRoom = formatRoomNumber(
    roomInfo.roomDetails?.name,
    roomInfo.bedDetails?.name
  )

  return (
    <LinkCard
      href={href}
      className={twx(
        'border border-transparent p-4 shadow hover:border-august-primary hover:bg-gray-13',
        {
          'border-secondary-10 bg-secondary-11 grayscale hover:bg-secondary-11':
            disabled,
        },
        cardClassName
      )}
      anchorClassName={twx(
        'focus:outline-button-primary-color',
        anchorClassName
      )}
    >
      <div className={tw`flex w-full flex-col`}>
        <div
          className={twx(
            'flex w-full flex-row items-start justify-between gap-5'
          )}
        >
          <div className={tw`flex flex-row items-center justify-start gap-5`}>
            <div
              className={tw`h-[48px] w-[48px] overflow-hidden rounded-md shadow-photo`}
            >
              <img
                src={
                  person.profilePhotoUrl
                    ? person.profilePhotoUrl
                    : getProfileSvgPath(person as PickPartial<Person, 'gender'>)
                }
                alt=""
                aria-hidden="true"
                className={tw`h-full w-full`}
              />
            </div>
            <div className={tw`flex flex-col gap-1 py-1`}>
              <div className={tw`flex gap-2 text-sm font-medium`}>
                {getFullName(person.name)}
                {person.outOfFacilityDetail && (
                  <Pill shape="rectangle" className={tw`bg-gray-06`}>
                    Away
                  </Pill>
                )}
              </div>
              {formattedRoom.length > 0 && (
                <div className={tw`text-sm text-gray-07`}>
                  <Icon name="bed" className={tw`mr-2`} />
                  <span>{formattedRoom}</span>
                </div>
              )}
            </div>
          </div>
          <div className={tw`py-1`}>{headerEnd}</div>
        </div>
        <div className={tw`ml-[48px] pl-4`}>{children}</div>
      </div>
    </LinkCard>
  )
}
